// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  SearchOutlined,
  StopOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  SearchOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const dashboard = {
  id: 'Documents',
  title: 'Documents',
  type: 'group',
  children: [
    {
      id: 'Search',
      title: <FormattedMessage id="Document Search" />,
      type: 'item',
      url: 'documents/search',
      icon: icons.SearchOutlined
    },
    /* {
      id: 'Insights',
      title: <FormattedMessage id="Insights" />,
      type: 'item',
      url: 'newell/insights',
      icon: icons.LineChartOutlined
    }, */
    {
      id: 'Collections',
      title: <FormattedMessage id="Collections"/>,
      type: 'item',
      url: 'documents/saved',
      icon: icons.FolderOutlined
    },
    {
      id: 'Data Sources',
      title: <FormattedMessage id="Data Sources"/>,
      type: 'item',
      url: 'documents/data',
      icon: icons.DatabaseOutlined
    },
    /* {
      id: 'Ad',
      title: <FormattedMessage id="Ad Details" />,
      type: 'item',
      url: 'dashboard/addetails',
      icon: icons.ChromeOutlined
    } */
  ],
};

export default dashboard;

/*
 {
  id: 'Insights',
  title: <FormattedMessage id="Insights" />,
  type: 'item',
  url: 'newell/insights',
  icon: icons.LineChartOutlined
},  */