// project import
import other from './other';
import dashboard from './dashboard';
import insights from './insights'
import agent from './chat'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard,other,insights,agent]
};

export default menuItems;
