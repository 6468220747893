// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  SearchOutlined,
  StopOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  SearchOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined,
  VideoCameraOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'Multimedia',
  title: 'Multimedia',
  type: 'group',
  children: [
    {
      id: 'Video Search',
      title: <FormattedMessage id="Video Search" />,
      type: 'item',
      url: 'multimedia/videosearch',
      icon: icons.VideoCameraOutlined
    },
    {
      id: 'Video Collections',
      title: <FormattedMessage id="Collections" />,
      type: 'item',
      url: 'multimedia/collections',
      icon: icons.FolderOutlined
    },
  ]
};

export default other;
