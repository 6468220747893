import React, { createContext,useContext, useState,useEffect } from 'react';
import {TableData} from 'api/dashApis';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import {data} from './data'
import {shots} from './shots_v2';
import {assets} from './all_metadata'
import useLocalStorage from 'hooks/useLocalStorage';
import {callaway_data} from 'data/callaway_data_frontend'
export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {

    const { getAccessTokenSilently } = useAuth0();
    
    // Table Data
    const [table, setTable] = useState([]);
    const [axisOptions, setAxisOptions] = useState([]);

    const getCampaigns = (data, asset_url) => {
        const campaignsForAsset = data
            .filter(item => item.asset_url === asset_url)
            .flatMap(item => {
                const campaignString = JSON.stringify(item.campaign_id) || '';
                return campaignString
                    .replace(/{|}/g, '') 
                    .split(',')
                    .map(campaign => campaign.trim())
                    .filter(campaign => campaign !== '');
            });
        const uniqueCampaigns = [...new Set(campaignsForAsset)];
        return `{${uniqueCampaigns.join(",")}}`;
    };
    
    const aggregateAssets = (data, metrics) => {
        // Grouping the data by asset_url
        const grouped = data.reduce((acc, row) => {
            if (!acc[row.asset_url]) {
                acc[row.asset_url] = [];
            }
            acc[row.asset_url].push(row);
            return acc;
        }, {});
        
        // Averaging the metrics for each asset_url
        return Object.entries(grouped).map(([asset_url, assetRows]) => {
            const firstRow = assetRows[0];
            const averagedMetrics = metrics.reduce((acc, metric) => {
                const totalValue = assetRows.reduce((total, row) => total + (row[metric] || 0), 0);
                acc[metric] = totalValue / assetRows.length;
                return acc;
            }, {});
            return { 
                asset_url, 
                campaigns: getCampaigns(data, asset_url),
                type: firstRow.type, 
                platform_id: firstRow.platform_id, 
                num_5sec_shots: Math.ceil(firstRow.num_5sec_shots), 
                video_length_sec: firstRow.video_length_sec,
                ...averagedMetrics 
            };
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            const { tableData } = await TableData({startDate:'2000-01-01',endDate:'2025-01-01'}, 1607, token);            
            
            const newAxisOptions = ['clicks', 'impressions', 'cost', 'ctr', 'cpm', 'cpc', 'fb_roas', 'purchase_rate', 'cost_per_purchase', 'purchase_value', 'purchase_count', 'onsite_web_add_to_cart_value', 'onsite_web_purchase_value']
            const assetsData = aggregateAssets(tableData, newAxisOptions);

            newAxisOptions.forEach(metric => {
                const sortedData = [...assetsData].sort((a, b) => b[metric] - a[metric]);
                const topThreshold = Math.floor(0.3 * sortedData.length);
                const bottomThreshold = Math.ceil(0.7 * sortedData.length);
        
                for (let i = 0; i < sortedData.length; i++) {
                    const performanceKey = `performance_${metric}`;
                    if (sortedData[i][metric] === 0) {
                        sortedData[i][performanceKey] = 2;
                    } else if (i < topThreshold) {
                        sortedData[i][performanceKey] = 1;
                    } else if (i >= bottomThreshold) {
                        sortedData[i][performanceKey] = 2;
                    } else {
                        sortedData[i][performanceKey] = 3;
                    }
                }
            });
    
            setTable(assetsData);
            setAxisOptions(newAxisOptions);
        };

        fetchData();
    }, []);

    const [assetSplit, setAssetSplit] = useState([]);

    useEffect(() => {
        if (table && table.length > 0) {
            const splits = table.flatMap(row => {
                const splitEntries = [];
                for (let i = 0; i < row.num_5sec_shots; i++) {
                    splitEntries.push({
                        ...row,
                        startTime: i * 5,
                        endTime: (i + 1) * 5,
                    });
                }
                return splitEntries;
            });
            setAssetSplit(splits);
        }
    }, [table]);

    const [assets2, setAssets2] = useState(
        callaway_data
        /* assets.map(asset => ({
            ...asset,
            url: asset.url.replace(
                'https://storage.cloud.google.com/',
                'https://storage.googleapis.com/'
            )
        })) */
    );
    const CallApi = async (url, data) => {
        return await axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(function (response) {
            const returnData = response.data
            returnData.responseCode = response?.status
            return returnData
          })
          .catch(function (error) {
            if (error?.response) {
                return {}
              /* if (error?.response?.status === 401) {
                setTimeout(() => {
                  localStorage.clear()
                  window.location.href = '/'
                }, 1000)
              }
              return error?.response?.data */
            } else {
              return {}
            }
          })
    };
    const [loading, setLoading] = useState(false)

    const [tableOrdered, setTableOrdered] = useState(null);
    const [result,setResult] = useState(null)

    const onSearch = async (value) => {

        if (!value.trim()) { 
            setTableOrdered(null)
            setLoading(false)
            return; 
        }

        setLoading(true)

        const body2 = {
            query: value
        };
        const body3 = {request: 
            {query: value, 
            search_type: "mmr", 
            search_kwargs: {'k': 10, 'lambda_mult': 0.5, 'fetch_k': 50}
            }
        }
        const body = {request: 
            {query: value, 
            search_type: "similarity_score_threshold", 
            search_kwargs: {k:20, score_threshold: confidence}
            }
        }
        console.log(body)

        const response2 = [{
            "description": "A young boy with tousled brown hair stands near a window, his eyes tightly closed and mouth wide open in an emotional outcry. He appears to be in the midst of a strong reaction, possibly expressing distress or frustration. He is wearing a white t-shirt adorned with various graphics and is dressed in blue jeans, standing on a wooden floor with gray socks featuring green stripes. The atmosphere suggests a moment of heightened emotion, capturing the essence of a child's struggle to manage his feelings.\n",
            "end_time": "8.44",
            "num_shots": "21.0",
            "start_time": "4.64",
            "url": "https://storage.cloud.google.com/open-store/SSJ17_OldBestPerformer_Fix_9x16_V5.mp4"
        },
        {
            "description": "A person with long hair, wearing a gray top and white pants, is nestled inside a suspended, multi-colored hammock, consisting of vibrant shades of purple, blue, and turquoise. They are holding the edges of the hammock close to their face, appearing to be in a comforting and cocoon-like embrace. Their legs, extended outward, show white-clad feet, seemingly in a relaxed position. The scene is set in a cozy living room with a dark sofa in the background. The atmosphere evokes a sense of soothing relaxation, suggesting the hammock's potential therapeutic use for providing calmness.",
            "end_time": "6.072733",
            "num_shots": "22.0",
            "start_time": "4.6379660000000005",
            "url": "https://storage.cloud.google.com/open-store/OS_SensoryJoy_Kids_TopPerformer_Iterations_1x1_V3.1.mp4"
        }
        ]
        // "https://open-store-rtt6bollsa-uc.a.run.app/send/chat"
        const response = await CallApi("https://open-store-rtt6bollsa-uc.a.run.app/send/callaway", body);
        console.log(response)

        setResult(response);

    };

    useEffect(() => {
        if (result && result.results && result.results.length > 0) {
    
            const matchedRecords = result.results.map(asset => {    
                return assets2.find(record => 
                    record.video_url.includes(asset.url) &&
                    Number(record.start_time) === Number(asset.start_time) && 
                    Number(record.end_time) === Number(asset.end_time)
                );
            }).filter(record => record !== undefined); // Only keep records that have matches
    
            console.log('matchedRecords', matchedRecords);
    
            matchedRecords.forEach(matchedRecord => {
                const index = assets2.findIndex(record => record === matchedRecord);
                if (index !== -1) {
                    assets2[index].flagged = true;  // or whatever flagging mechanism you'd like
                }
            });
            console.log(matchedRecords)
            setTableOrdered(matchedRecords);
            setLoading(false);
        }
        else if (result) {
            setLoading('none');   
            setTableOrdered([]);    
        }
    }, [result]);

    const [icon, setIcon] = useState(true);
    
    function switchView() {
        setIcon(!icon);
    }

    const oneAd = table[0]

    const [filledStates, setFilledStates] = useLocalStorage('saved', {}); 

    const handleClick = (label) => {
        onSearch(label);
    }

    const [confidence,setConfidence] = useState(.5)

    const handleSliderChange = (x) => {
        setConfidence(x);
        //onSearch(value)
    };
    
    return (
        <VideoContext.Provider value={{ confidence, handleSliderChange,handleClick, filledStates, setFilledStates,
        loading, assets2, tableOrdered, table, axisOptions, onSearch, switchView, icon, setIcon, oneAd, assetSplit }}>
            {children}
        </VideoContext.Provider>
    );
};