import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router-dom';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render 
const DashboardPlatform = Loadable(lazy(() => import('pages/dashboard/platform')));
const DashboardAdvertiser = Loadable(lazy(() => import('pages/dashboard/advertiser')));
const DashboardAdDetails = Loadable(lazy(() => import('pages/dashboard/ad')));


const DashboardSearch = Loadable(lazy(() => import('pages/newell/search')));
const DataSources = Loadable(lazy(() => import('pages/newell/datasources')));
const ConsumerInsights = Loadable(lazy(() => import('pages/newell/insights')));
const VideoSearch = Loadable(lazy(() => import('pages/video/video')));
const SavedPage = Loadable(lazy(() => import('pages/video/saved')));
const ChatPage = Loadable(lazy(() => import('pages/agent/chat')));
const SlackInfo = Loadable(lazy(() => import('pages/newell/saved')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        // Redirect root
        {
          path: '/',
          element: <Navigate to="/documents/search" />
        },
        {
          path: 'documents',
          children: [
            {
              path: 'search',
              element: <DashboardSearch />
            },
            {
              path: 'saved',
              element: <SlackInfo />
            },
            /* {
              path: 'insights',
              element: <ConsumerInsights />
            }, */
            {
              path: 'data',
              element: <DataSources />
            }
          ]
        },
        {
          path: 'multimedia',
          children: [ 
            {
              path: 'videosearch',
              element: <VideoSearch />
            },
            {
              path: 'collections',
              element: <SavedPage />
            },
          ]
        },
        {
          path: 'dashboard',
          children: [ 
            {
              path: 'creative',
              element: <DashboardPlatform />
            },
            {
              path: 'reviews',
              element: <ConsumerInsights />
            },
            /* {
              path: 'collections',
              element: <SavedPage />
            }, */
          ]
        },
        {
          path: 'agent',
          children: [ 
            {
              path: 'chat',
              element: <ChatPage />
            },
          ]
        },
        /* { 
          path: 'dashboard',
          children: [
            {
              path: 'advertiser',
              element: <DashboardAdvertiser />
            },
            {
              path: 'search',
              element: <DashboardSearch />
            },
            {
              path: ':id', 
              element: <DashboardPlatform />
            },
            {
              path: 'details',
              children: [
                {
                  path: ':ad',
                  element: <DashboardAdDetails />
                }
              ]
            },
          ]
        }, */
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
