import { PostData } from "utils/apiHandler";
import {isNumeric} from "utils/dashutils";

// APIs and data helper functions 

// Axis options
const generateAxisOptions = (data) => {
    const aliases = ['campaign_id', 'advertiser_id', 'platform_id', 'type'];

    return Object.keys(data[0])
        .filter(key => {
            if (aliases.includes(key)) {
                return false;
            }
            const sum = data.reduce((acc, item) => acc + (isNumeric(item[key]) ? item[key] : 0), 0);
            return sum > 0;
        });
};

// Table Data ------------------------------------------------------------------------------------

// API table data
export const TableData = async (dateSelected, id, token) => {
    const body = {
      advertiserId: id,
      startDate: dateSelected.startDate,
      endDate: dateSelected.endDate
    };

    try {
        const response = await PostData("/table/getTable/", body, token);

        if (response && response.length > 0) {
            const axisOptions = generateAxisOptions(response);
            return { tableData: response, axisOptions };
        } else {
            console.log('no response');
            return { tableData: [], axisOptions: [] };
        }
    } catch (error) {
        console.error('Error fetching table data:', error);
        return { tableData: [], axisOptions: [] };
    }
};


// AI Tags ------------------------------------------------------------------------------------

// Create Opportunity tags
const enhanceResponse = (response) => {
    const sortedByRoas = [...response].sort((a, b) => {
        const aRoas = Math.max(a.fb_roas, a.tt_roas);
        const bRoas = Math.max(b.fb_roas, b.tt_roas);
        return bRoas - aRoas;
    });
    const sortedByCtr = [...response].sort((a, b) => b.ctr - a.ctr);
    const sortedByImpressions = [...response].sort((a, b) => a.impressions - b.impressions);

    const top30PercentIndex = Math.floor(response.length * 0.3) - 1;
    const bottom50PercentIndex = Math.ceil(response.length * 0.4) - 1;

    const roasThreshold = Math.max(sortedByRoas[top30PercentIndex].fb_roas, sortedByRoas[top30PercentIndex].tt_roas);
    const ctrThreshold = sortedByCtr[top30PercentIndex].ctr;
    const impressionsThreshold = sortedByImpressions[bottom50PercentIndex].impressions;

    return response.map(item => {
        item.flag = ((item.fb_roas > roasThreshold || item.tt_roas > roasThreshold) || item.ctr > ctrThreshold && item.impressions <= impressionsThreshold) ? 1 : 0;
        return item;
    });
};

// Api AI Tags
export const getAllTags = async (dateSelected, id, token) => {
    const body = {
        startDate: dateSelected.startDate,
        endDate: dateSelected.endDate,
        advertiserId: id,
    };

    try {
        const response = await PostData("/aiall/getAiall/", body, token);

        if (response && response.length > 0) {
            const enhanced = enhanceResponse(response);
            return enhanced.filter(item => item.subcategory !== null);
        } else {
            console.log('no response');
            return [];
        }
    } catch (error) {
        console.error('Error fetching tags:', error);
        return [];
    }
};

// ------------------------------------------------------------------------------------

// Tag Groups with Filters (for bubble chart)
export function tagGroups(data, catSelected, axisOptions) {
    let filteredData = data;

    const filters = [
      { list: catSelected, key: 'tag_category' },
    ];

    filters.forEach(filter => {
        if (filter.list.length > 0) {
            filteredData = filteredData.filter(row => filter.list.includes(row[filter.key]));
        }
    });

    const unique_groups = [...new Set(filteredData.map(item => item['subcategory']))];

    return unique_groups.map(name => {
        const rowsForCategory = filteredData.filter(row => row['subcategory'] === name);
        const campaignsList = rowsForCategory.flatMap(row => {
            const matches = row.campaigns.match(/\d+/g);
            return matches ? matches.map(Number) : [];
        });

        const uniqueCampaigns = [...new Set(campaignsList)];
        const campaigns = "{" + uniqueCampaigns.join(",") + "}";

        const aggregatedMetrics = axisOptions.reduce((acc, metric) => {
            const totalValue = rowsForCategory.reduce((total, row) => total + (row[metric] || 0), 0);
            acc[metric] = totalValue / rowsForCategory.length;
            return acc;
        }, {});

        const flag = rowsForCategory[0].flag;
        return { name, flag, campaigns, ...aggregatedMetrics };
    });
}

// AD SETS / AD CATEGORIES
// Group adset or adname
export const groupByCategory = (currentTable, axisOptions, catName) => {
    const categories = currentTable && currentTable.length > 0 ? currentTable.map(item => item[catName]) : [];
    const uniqueCategories = [...new Set(categories.flatMap(item => item.split('|').map(s => s.trim())))];

    return uniqueCategories.map(name => {
        const rowsForCategory = currentTable.filter(row => row[catName].includes(name));
        const campaigns = "{" + [...new Set(rowsForCategory.map(row => row.campaign_id))].join(",") + "}";
        
        const aggregatedMetrics = axisOptions.reduce((acc, metric) => {
            acc[metric] = rowsForCategory.reduce((sum, row) => sum + (row[metric] || 0), 0) / rowsForCategory.length;
            return acc;
        }, Object.fromEntries(axisOptions.map(metric => [metric, 0])));
        
        return { name, campaigns, ...aggregatedMetrics };
    });
}

// ------------------------------------------------------------------------------------

// API Time series
export const fetchLineData = async (advertiserId, token) => {
    const body = {
      advertiserId: advertiserId,
    };
  
    try {
      const response = await PostData("/time/getTime/", body, token);
      if (response && response.length > 0) {
        return response;
      } else {
        console.log('no response');
        return [];
      }
    } catch (error) {
      console.error("Error fetching line data:", error);
      return [];
    }
};

// Aggregate for Timeseries formatting
export function aggregateDataByDate(data, axisOptions, axisConfig) {
    const aggregatedData = {};

    // Intermediate structure to store count for average calculations
    const counts = {};

    data.forEach(item => {
        if (!aggregatedData[item.date]) {
            aggregatedData[item.date] = {};
            counts[item.date] = {};
        }

        Object.keys(item).forEach(key => {
            // Only process metrics that are in axisOptions and are defined in axisConfig
            if (axisOptions.includes(key) && axisConfig[key]) {
                if (!aggregatedData[item.date][key]) {
                    aggregatedData[item.date][key] = 0;
                    counts[item.date][key] = 0;
                }

                aggregatedData[item.date][key] += item[key];
                counts[item.date][key]++;
            }
        });
    });

    // Post-process to compute average
    Object.keys(aggregatedData).forEach(date => {
        Object.keys(aggregatedData[date]).forEach(metricKey => {
            if (axisConfig[metricKey]?.agg === 'avg') {
                aggregatedData[date][metricKey] /= counts[date][metricKey];
            }
        });
    });

    return aggregatedData;
}

