
// material-ui
//import { Box, useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
import store from 'assets/images/logo.png'
import { SearchBox } from "sections/dashboard/search/searchbox";
import {Grid,Box,Stack,Typography,Select,MenuItem,Autocomplete,Chip,TextField} from "@mui/material";
import IconButton from 'components/@extended/IconButton';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Popper } from '@mui/material';
import { SearchContext, SearchProvider } from "contexts/SearchContext";
import React, { createContext, useContext, useState, useEffect } from "react";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Button from '@mui/material/Button';

//import Search from './Search';
//import Message from './Message';
//import Notification from './Notification';
//import MobileSection from './MobileSection';
//import MegaMenuSection from './MegaMenuSection';


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderItems = () => {

  //const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <> 
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {/* <div style={{display: 'flex',alignItems:'center',paddingLeft:'20px'}}>
        <img src={store} alt='logo' style={{width:'160px',height:'30px'}}/>
      </div> */}

        {<Stack style={{paddingLeft:'20px',marginTop: '10px',marginBottom: '10px' }}>
          <Stack direction='row' alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                  <SearchBox/>
              </div>

          </Stack>
        </Stack>}
      
      <Profile />
    </Box>

      {/* {!matchesXs && <Search />} */}
      {/* {!matchesXs && megaMenu} */}
      {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Notification /> */}
      {/* <Message /> */}
      {/* {matchesXs && <MobileSection />} */}
    </>
  );
};

const HeaderContent = () => {
  return (
      <HeaderItems />
  );
};

export default HeaderContent;

