import React, { createContext, useContext, useState, useEffect } from "react";
import useLocalStorage from 'hooks/useLocalStorage';
import {data} from 'data/sentiment';
import OpenAI from 'openai'; 
import {CallApi} from 'utils/apiHandler'
import {filedata} from 'data/docs_data'

export const SearchContext = createContext();

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true })

function sliceToWordLimit(texts, wordLimit) {
  let cumulativeText = '';
  let currentWordCount = 0;

  for (const text of texts) {
    const words = text.trim().split(/\s+/);
    for (const word of words) {
      if (currentWordCount === wordLimit) {
        // Stop if we reach the word limit
        return cumulativeText.trim();
      }
      cumulativeText += `${word} `;
      currentWordCount++;
    }
  }

  return cumulativeText.trim(); // Return the cumulative text up to the word limit
}


export const SearchProvider = ({ children }) => {

    const segmentBrandMap = {
      "Commercial Solutions": ["Mapa", "Quickie", "Rubbermaid", "Rubbermaid Commercial Products", "Spontex"],
      "Home Appliances": ["Calphalon", "Crockpot", "Mr. Coffee", "Oster", "Sunbeam"],
      "Home Solutions": ["Ball", "Chesapeake Bay Candle", "FoodSaver", "Sistema", "WoodWick", "Yankee Candle"],
      "Learning and Development": ["Aprica", "Baby Jogger", "Dymo", "Elmer’s", "EXPO", "Graco", "Mr. Sketch", "NUK", "Paper Mate", "Parker", "Prismacolor", "Sharpie", "Tigex", "Waterman", "X-ACTO"],
      "Outdoor and Recreation": ["Campingaz", "Coleman", "Contigo", "ExOfficio", "Marmot"],
    };

    const brands = [
      "Mapa","Quickie","Rubbermaid","Rubbermaid Commercial Products","Spontex","Calphalon","Crockpot","Mr. Coffee","Oster","Sunbeam","Ball",
      "Chesapeake Bay Candle","FoodSaver","Sistema","WoodWick","Yankee Candle","Aprica","Baby Jogger","Dymo","Elmer’s","EXPO",
      "Graco","Mr. Sketch","NUK","Paper Mate","Parker","Prismacolor","Sharpie","Tigex","Waterman","X-ACTO","Campingaz","Coleman",
      "Contigo","ExOfficio","Marmot"];


    const [files,setFiles] = useLocalStorage('files',filedata);


    useEffect(() => {
      handleClear()
    }, []);

    //const [question, setQuestion] = useState(''); // question start

    //console.log(filedata)
    //const [fileFeatures, setFileFeatures] = useLocalStorage()

    const [selectedSegment, setSelectedSegment] = useState('');
    const [selectedBrand,setSelectedBrand] = useState('')
    const [filteredBrands, setFilteredBrands] = useState([]);
    const [sortBy, setSortBy] = useState(''); 

    const [selectedFile, setSelectedFile] = useState(undefined); // pdf viewer

    const [filesOrdered, setFilesOrdered] = useState(null); // SNIPPETS
    const [uniqueFiles,setUniqueFiles] = useState(null) // NEW FILES

    const [result, setResult] = useState(null); // raw result from api
    const [context,setContext] = useLocalStorage([]) // context only from api
    const [isEmpty, setIsEmpty] = useState(false)
    
    function buildYQLQuery(params, limit, profile) {
      let yqlQuery = "select * from sources *";
      let conditions = [];
    
      // Handle document_id
      if (Array.isArray(params.document_id) && params.document_id.length > 0) {
        const documentIds = params.document_id.map(id => `'${id}'`).join(', ');
        conditions.push(`id contains equiv(${documentIds})`);
      } else if (typeof params.document_id === 'string') {
        conditions.push(`id contains '${params.document_id}'`);
      }
      // Handle brand
      if (params.brand) {
        conditions.push(`brand contains '${params.brand}'`);
      }
    
      // Handle division
      if (params.division) {
        conditions.push(`division contains '${params.division}'`);
      }
    
      // Combine all conditions with 'AND' if there are any, otherwise just add userQuery
      if (conditions.length > 0) {
        yqlQuery += ' where ' + conditions.join(' AND ') + ' AND';
      } else {
        yqlQuery += ' where';
      }
    
      // Add the userQuery and limit part to the query
      yqlQuery += " userQuery() limit "+limit;
    
      return {
        yql: yqlQuery,
        query: params.query || "",
        ranking: {
          profile: profile
        }
      };
    }
    

    // API CALL  -----------------------------------
    const onSearch = async (question) => {
      setResponse([])
      setSelectedFile(undefined)

      const params = {  
        query: question,
        document_id: files.filter(file => file.filled).map(file => file.id) || null, // Could be an array, a single value, or omitted
        brand: selectedBrand || null, 
        division: selectedSegment || null 
      };
      const yqlJSONQuery = buildYQLQuery(params,'300',"fusion");

      const response = await CallApi(
        "https://newell-vespa-p6j3rbf6oa-uc.a.run.app/send/chat",
        yqlJSONQuery
      );

      processResults(response);
    };

    // Submit question -----------------------------------

    const handleSubmit = async (question) => {
      onSearch(question)
    };

    const handleClick = (label) => {
      onSearch(label);
    };
    
    // Replace records with search result -----------------------------------

    const processResults = (result) => {
      if (result && result?.root?.children?.length > 0) {
        setIsEmpty(false);
    
        let matchedRecords = [];
        let allTexts = result.root.children.map(apiResult => apiResult.fields.content);
        let uniqueFilesMap = new Map();
    
        result.root.children.forEach((apiResult) => {
          const matchingFiles = files.filter(file => file.id === apiResult.fields.id); // match doc id
          matchingFiles.forEach(matchingFile => {
            // Add the record with dimensionsList for matchedRecords
            const recordWithDimensions = {
              ...matchingFile,
              text: apiResult.fields.content,
              page_number: apiResult.fields.page_number,
              dimensionsList: matchingFile.dimensionsList || []
            };
            recordWithDimensions.dimensionsList.push([apiResult.fields.page_number, apiResult.fields.location.values]);
            matchedRecords.push(recordWithDimensions);
    
            // Update or add to uniqueFilesMap
            if (uniqueFilesMap.has(matchingFile.id)) {
              let existingFile = uniqueFilesMap.get(matchingFile.id);
              existingFile.dimensionsList.push([apiResult.fields.page_number, apiResult.fields.location.values]);
              uniqueFilesMap.set(matchingFile.id,existingFile);
            } else {
              // New file, add it to the map with dimensionsList
              uniqueFilesMap.set(matchingFile.id, { ...recordWithDimensions });
            }
          });
        });
    
        let uniqueFilesArray = Array.from(uniqueFilesMap.values());
        
        setUniqueFiles(uniqueFilesArray);
        console.log('uniqueFilesArray',uniqueFilesArray)
        setContext(allTexts);
        setFilesOrdered(matchedRecords);
    
        //fetchCompletion(allTexts);
      }
      else if (result && !result?.root?.children) {
        setIsEmpty(true);
        setFilesOrdered(null);
      }
    };
      

    // Toggle checkbox/ Saved -----------------------------------

    const toggleFilledStatus = (targetFile) => {
      // original
      setFiles((prevFiles) => {
        return prevFiles.map((file) => {
          if (file.id === targetFile.id) {
            return { ...file, filled: !file.filled }; 
          }
          return file;
        });
      });
      // replacement
      if (filesOrdered) {setFilesOrdered((prevFiles) => {
          return prevFiles.map((file) => {
            if (file.id === targetFile.id) {
              return { ...file, filled: !file.filled }; 
            }
            return file;
          });
        });
      }
      if (uniqueFiles) {setUniqueFiles((prevFiles) => {
        return prevFiles.map((file) => {
          if (file.id === targetFile.id) {
            return { ...file, filled: !file.filled }; 
          }
          return file;
        });
      });
      }
    };

    const toggleSavedStatus = (targetFile) => {
      setFiles((prevFiles) => {
        return prevFiles.map((file) => {
          if (file.id === targetFile.id) {
            return { ...file, saved: !file.saved }; 
          }
          return file;
        });
      });
      if (filesOrdered) {setFilesOrdered((prevFiles) => {
          return prevFiles.map((file) => {
            if (file.id === targetFile.id) {
              return { ...file, saved: !file.saved }; 
            }
            return file;
          });
        });
      }
    if (uniqueFiles) {setUniqueFiles((prevFiles) => {
        return prevFiles.map((file) => {
          if (file.id === targetFile.id) {
            return { ...file, saved: !file.saved }; 
          }
          return file;
        });
      });
      }
    };
    
  // Summary -----------------------------------

  const startSummary = () => {
    //fetchCompletion(context)
  };

  const [response, setResponse] = useLocalStorage('response',[]);

  const fetchCompletion = async (query, context) => {
    if (context && context.length > 0) {
      setResponse([]);
      try {
        const completion = await openai.chat.completions.create({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "Use the documents to answer the question. If the question is unclear, summarize the documents." },
            { role: "user", content: `Documents: ${context}\n Question: ${query}` },
          ],
          stream: true,
        });

        for await (const chunk of completion) {
          const newContent = chunk.choices[0].delta.content;
          setResponse(response => [...response, newContent]);
        }
      } catch (error) {
        console.error("Error fetching completion:", error);
      }
    }
  };

  // List of Related Questions -----------------------------------

  const [qList, setqList] = useLocalStorage('qList',[]);

  const getQlist = async (question) => {

      const completion = await openai.chat.completions.create({
          model: "gpt-3.5-turbo", 
          messages: [
              { role: "system", content: "Given a question, I need a list of 5 other questions that could be asked about the same topic. Provide the output in a python list. Do not add anything to the output. Do not make the list numbered, include a newline at the end of each." },
              { role: "user", content: question },
          ],
      });
      const content = completion.choices[0].message.content
      const questionList = content.split('\n');
      setqList(questionList)
  };

  // Fitlers/ Interactions -----------------------------------

  const handleSegmentChange = (event, value) => {
    setSelectedSegment(value);
    setFilteredBrands(value ? segmentBrandMap[value] : []);
  };

  const handleBrandChange = (event, value) => {
    setSelectedBrand(value);
  };

  const getAIMessage = async (localQ) => {
    console.log('AI MESSAGE',localQ)

    const params = {
      query: localQ,
      document_id: files.filter(file => file.filled).map(file => file.id) || null, // Could be an array, a single value, or omitted
      brand: selectedBrand || null, // Optional
      division: selectedSegment || null // Optional
    };
    const yqlJSONQuery = buildYQLQuery(params,'300','fusion');
    console.log(JSON.stringify(yqlJSONQuery, null, 2));

    const response = await CallApi(
      "https://newell-vespa-p6j3rbf6oa-uc.a.run.app/send/chat",
      yqlJSONQuery
    );

    let allTexts = '';
    let slicedText = '';

    if (response && response.root && Array.isArray(response.root.children)) {
        allTexts = response.root.children.map(apiResult => apiResult.fields.content).join(' ');
        slicedText = sliceToWordLimit(allTexts, 1500);
    }

    console.log('slicedText', slicedText);

    processResults(response);
    fetchCompletion(localQ,slicedText)
  };

  const handleClear = () => {
    console.log('handle clearing')
    setIsEmpty(false)
    //setQuestion('')
    setFilesOrdered(null)
    setResult(null)
    setResponse([])
    setContext([])
    setSelectedBrand(null)
    setSelectedSegment(null)
    setUniqueFiles(null)
    setSelectedSegment('')
    setFilteredBrands([])
    setSortBy('')
    setSelectedFile(undefined)
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        return { ...file, filled: false };
      });
    });
  };

  const handleExit = () => {
    setSelectedFile(undefined)
  }

  return (
    <SearchContext.Provider
      value={{
        selectedFile, filteredBrands, selectedSegment,setSortBy,setSelectedFile,brands,segmentBrandMap,handleSegmentChange,data,
        handleClick,files,toggleFilledStatus,filesOrdered,setFilesOrdered,onSearch,response,getAIMessage,
        handleSubmit,setResponse,uniqueFiles,toggleSavedStatus, handleClear, isEmpty, handleBrandChange, handleExit
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
