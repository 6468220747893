// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  SearchOutlined,
  StopOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined,
  AreaChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  SearchOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined,
  AreaChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const insights = {
  id: 'Dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'Insights Dashboard',
      title: <FormattedMessage id="Creative Insights"/>,
      type: 'item',
      url: 'dashboard/creative',
      icon: icons.LineChartOutlined
    },
    {
        id: 'Reviews Dashboard',
        title: <FormattedMessage id="Product Reviews"/>,
        type: 'item',
        url: 'dashboard/reviews',
        icon: icons.AreaChartOutlined
      },
  ],
};

export default insights;
