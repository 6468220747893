export const callaway_data = [{'video_id': 'Download video (43)..txt',
  'num_clips': 4,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.366666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(43)..mp4',
  'likes': 55900,
  'comments': 602,
  'collections': 191000},
 {'video_id': 'Download video (43)..txt',
  'num_clips': 4,
  'clip_num': 1,
  'start_time': 3.4,
  'end_time': 5.666666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(43)..mp4',
  'likes': 55900,
  'comments': 602,
  'collections': 191000},
 {'video_id': 'Download video (43)..txt',
  'num_clips': 4,
  'clip_num': 2,
  'start_time': 5.7,
  'end_time': 10.966666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(43)..mp4',
  'likes': 55900,
  'comments': 602,
  'collections': 191000},
 {'video_id': 'Download video (43)..txt',
  'num_clips': 4,
  'clip_num': 3,
  'start_time': 11.0,
  'end_time': 17.0,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(43)..mp4',
  'likes': 55900,
  'comments': 602,
  'collections': 191000},
 {'video_id': 'Download video (6)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 12.912912,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(6)..mp4',
  'likes': 6358,
  'comments': 26,
  'collecitons': 148},
 {'video_id': 'Download video (22)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 17.784451,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(22)..mp4',
  'likes': 42200,
  'comments': 441,
  'collections': 147000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 0.959292,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 1,
  'start_time': 1.001001,
  'end_time': 2.669336,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 2,
  'start_time': 2.7110440000000002,
  'end_time': 3.8788780000000003,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 3,
  'start_time': 3.9205870000000003,
  'end_time': 4.921588,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 4,
  'start_time': 4.963296,
  'end_time': 7.799466,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 5,
  'start_time': 7.841174,
  'end_time': 12.30397,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 6,
  'start_time': 12.345679,
  'end_time': 13.34668,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 7,
  'start_time': 13.388388,
  'end_time': 14.264264,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 8,
  'start_time': 14.305972,
  'end_time': 15.14014,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 9,
  'start_time': 15.181848,
  'end_time': 16.141141,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 10,
  'start_time': 16.182849,
  'end_time': 19.22756,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 11,
  'start_time': 19.269269,
  'end_time': 20.52052,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 12,
  'start_time': 20.562228,
  'end_time': 21.855188,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 13,
  'start_time': 21.896896,
  'end_time': 22.689356,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (20)..txt',
  'num_clips': 15,
  'clip_num': 14,
  'start_time': 22.731064,
  'end_time': 24.065732,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(20)..mp4',
  'likes': 48600,
  'comments': 255,
  'collections': 45000},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 1.376376,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 1,
  'start_time': 1.418084,
  'end_time': 3.628628,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 2,
  'start_time': 3.670337,
  'end_time': 7.132132,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 3,
  'start_time': 7.17384,
  'end_time': 10.343677,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 4,
  'start_time': 10.385385,
  'end_time': 12.971304,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 5,
  'start_time': 13.013013,
  'end_time': 14.806473,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 6,
  'start_time': 14.848181,
  'end_time': 17.18385,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 7,
  'start_time': 17.225558,
  'end_time': 20.228561,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 8,
  'start_time': 20.27027,
  'end_time': 22.814481,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (4)..txt',
  'num_clips': 10,
  'clip_num': 9,
  'start_time': 22.856189,
  'end_time': 27.318985,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(4)..mp4',
  'likes': 8452,
  'comments': 47,
  'collecitons': 113},
 {'video_id': 'Download video (39)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 34.3,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(39)..mp4',
  'likes': 5386,
  'comments': 94,
  'collecitons': 175},
 {'video_id': 'Download video (41)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 2.566666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(41)..mp4',
  'likes': 15700,
  'comments': 89,
  'collections': 24000},
 {'video_id': 'Download video (41)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 2.583333,
  'end_time': 6.483333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(41)..mp4',
  'likes': 15700,
  'comments': 89,
  'collections': 24000},
 {'video_id': 'Download video (41)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 6.516666,
  'end_time': 13.383333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(41)..mp4',
  'likes': 15700,
  'comments': 89,
  'collections': 24000},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 2.616666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 1,
  'start_time': 2.633333,
  'end_time': 5.416666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 2,
  'start_time': 5.433333,
  'end_time': 8.45,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 3,
  'start_time': 8.466666,
  'end_time': 11.75,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 4,
  'start_time': 11.766666,
  'end_time': 13.45,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 5,
  'start_time': 13.466666,
  'end_time': 15.85,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 6,
  'start_time': 15.866666,
  'end_time': 18.116666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 7,
  'start_time': 18.133333,
  'end_time': 19.383333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 8,
  'start_time': 19.4,
  'end_time': 20.95,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 9,
  'start_time': 20.966666,
  'end_time': 22.55,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 10,
  'start_time': 22.566666,
  'end_time': 24.05,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 11,
  'start_time': 24.066666,
  'end_time': 25.616666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 12,
  'start_time': 25.633333,
  'end_time': 29.75,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 13,
  'start_time': 29.766666,
  'end_time': 31.316666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 14,
  'start_time': 31.333333,
  'end_time': 33.283333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 15,
  'start_time': 33.3,
  'end_time': 39.25,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 16,
  'start_time': 39.266666,
  'end_time': 40.35,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 17,
  'start_time': 40.366666,
  'end_time': 42.05,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 18,
  'start_time': 42.066666,
  'end_time': 44.283333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 19,
  'start_time': 44.3,
  'end_time': 46.516666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (45)..txt',
  'num_clips': 21,
  'clip_num': 20,
  'start_time': 46.533333,
  'end_time': 48.95,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(45)..mp4',
  'likes': 1060,
  'comments': 9,
  'collecitons': 18},
 {'video_id': 'Download video (24)..txt',
  'num_clips': 6,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 0.791666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(24)..mp4',
  'likes': 8290,
  'comments': 33,
  'collecitons': 250},
 {'video_id': 'Download video (24)..txt',
  'num_clips': 6,
  'clip_num': 1,
  'start_time': 0.833333,
  'end_time': 7.833333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(24)..mp4',
  'likes': 8290,
  'comments': 33,
  'collecitons': 250},
 {'video_id': 'Download video (24)..txt',
  'num_clips': 6,
  'clip_num': 2,
  'start_time': 7.875,
  'end_time': 10.375,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(24)..mp4',
  'likes': 8290,
  'comments': 33,
  'collecitons': 250},
 {'video_id': 'Download video (24)..txt',
  'num_clips': 6,
  'clip_num': 3,
  'start_time': 10.416666,
  'end_time': 11.583333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(24)..mp4',
  'likes': 8290,
  'comments': 33,
  'collecitons': 250},
 {'video_id': 'Download video (24)..txt',
  'num_clips': 6,
  'clip_num': 4,
  'start_time': 11.625,
  'end_time': 29.416666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(24)..mp4',
  'likes': 8290,
  'comments': 33,
  'collecitons': 250},
 {'video_id': 'Download video (24)..txt',
  'num_clips': 6,
  'clip_num': 5,
  'start_time': 29.458333,
  'end_time': 40.458333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(24)..mp4',
  'likes': 8290,
  'comments': 33,
  'collecitons': 250},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 1.6,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 1,
  'start_time': 1.616666,
  'end_time': 3.116666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 2,
  'start_time': 3.133333,
  'end_time': 5.366666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 3,
  'start_time': 5.383333,
  'end_time': 7.066666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 4,
  'start_time': 7.083333,
  'end_time': 8.316666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 5,
  'start_time': 8.333333,
  'end_time': 9.766666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 6,
  'start_time': 9.783333,
  'end_time': 11.366666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 7,
  'start_time': 11.383333,
  'end_time': 12.85,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 8,
  'start_time': 12.866666,
  'end_time': 14.433333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (19)..txt',
  'num_clips': 10,
  'clip_num': 9,
  'start_time': 14.45,
  'end_time': 16.416666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(19)..mp4',
  'likes': 68200,
  'comments': 233,
  'collections': 89000},
 {'video_id': 'Download video (2)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 1.666666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(2)..mp4',
  'likes': 8245,
  'comments': 27,
  'collecitons': 520},
 {'video_id': 'Download video (2)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 1.7,
  'end_time': 7.9,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(2)..mp4',
  'likes': 8245,
  'comments': 27,
  'collecitons': 520},
 {'video_id': 'Download video (26)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 12.812812,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(26)..mp4',
  'likes': 505600,
  'comments': 1469,
  'collections': 11300},
 {'video_id': 'Download video (47)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 16.633333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(47)..mp4',
  'likes': 23200,
  'comments': 85,
  'collections': 61000},
 {'video_id': 'Download video (5)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 16.499833,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(5)..mp4',
  'likes': 24400,
  'comments': 209,
  'collections': 10000},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 1.459793,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 1,
  'start_time': 1.501501,
  'end_time': 3.003003,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 2,
  'start_time': 3.044711,
  'end_time': 7.799466,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 3,
  'start_time': 7.841174,
  'end_time': 10.844177,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 4,
  'start_time': 10.885885,
  'end_time': 13.304971,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 5,
  'start_time': 13.34668,
  'end_time': 14.889889,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 6,
  'start_time': 14.931598,
  'end_time': 17.934601,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 7,
  'start_time': 17.976309,
  'end_time': 18.685352,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 8,
  'start_time': 18.72706,
  'end_time': 20.061728,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 9,
  'start_time': 20.103436,
  'end_time': 21.980313,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 10,
  'start_time': 22.022022,
  'end_time': 22.814481,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 11,
  'start_time': 22.856189,
  'end_time': 24.566232,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 12,
  'start_time': 24.607941,
  'end_time': 25.942609,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 13,
  'start_time': 25.984317,
  'end_time': 27.277277,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 14,
  'start_time': 27.318985,
  'end_time': 28.653653,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 15,
  'start_time': 28.695362,
  'end_time': 29.821488,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 16,
  'start_time': 29.863196,
  'end_time': 30.739072,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 17,
  'start_time': 30.78078,
  'end_time': 31.489823,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 18,
  'start_time': 31.531531,
  'end_time': 32.407407,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 19,
  'start_time': 32.449115,
  'end_time': 33.3667,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (21)..txt',
  'num_clips': 21,
  'clip_num': 20,
  'start_time': 33.408408,
  'end_time': 35.118451,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(21)..mp4',
  'likes': 3690,
  'comments': 58,
  'collecitons': 61},
 {'video_id': 'Download video (40)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.5,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(40)..mp4',
  'likes': 13800,
  'comments': 86,
  'collections': 154000},
 {'video_id': 'Download video (40)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 3.533333,
  'end_time': 12.766666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(40)..mp4',
  'likes': 13800,
  'comments': 86,
  'collections': 154000},
 {'video_id': 'Download video (38)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.2,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(38)..mp4',
  'likes': 8968,
  'comments': 61,
  'collecitons': 206},
 {'video_id': 'Download video (38)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 3.233333,
  'end_time': 9.233333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(38)..mp4',
  'likes': 8968,
  'comments': 61,
  'collecitons': 206},
 {'video_id': 'Download video (38)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 9.266666,
  'end_time': 21.233333000000002,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(38)..mp4',
  'likes': 8968,
  'comments': 61,
  'collecitons': 206},
 {'video_id': 'Download video (42)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 6.433333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(42)..mp4',
  'likes': 2386,
  'comments': 20,
  'collecitons': 64},
 {'video_id': 'Download video (23)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 12.666666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(23)..mp4',
  'likes': 4179,
  'comments': 29,
  'collecitons': 108},
 {'video_id': 'Download video (7)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 15.949282,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(7)..mp4',
  'likes': 22000,
  'comments': 163,
  'collections': 44000},
 {'video_id': 'Download video (27)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 8.675342,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(27)..mp4',
  'likes': 33300,
  'comments': 131,
  'collections': 76000},
 {'video_id': 'Download video (3)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 8.416666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(3)..mp4',
  'likes': 95300,
  'comments': 260,
  'collections': 41000},
 {'video_id': 'Download video (3)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 8.458333,
  'end_time': 22.166666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(3)..mp4',
  'likes': 95300,
  'comments': 260,
  'collections': 41000},
 {'video_id': 'Download video (46)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.566666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(46)..mp4',
  'likes': 2585,
  'comments': 11,
  'collecitons': 159},
 {'video_id': 'Download video (46)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 3.6,
  'end_time': 10.966666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(46)..mp4',
  'likes': 2585,
  'comments': 11,
  'collecitons': 159},
 {'video_id': 'Download video (44)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.633333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(44)..mp4',
  'likes': 4983,
  'comments': 57,
  'collecitons': 129},
 {'video_id': 'Download video (44)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 3.666666,
  'end_time': 7.7,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(44)..mp4',
  'likes': 4983,
  'comments': 57,
  'collecitons': 129},
 {'video_id': 'Download video (1)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 7.266666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(1)..mp4',
  'likes': 9568,
  'comments': 42,
  'collecitons': 942},
 {'video_id': 'Download video (1)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 7.3,
  'end_time': 15.533333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(1)..mp4',
  'likes': 9568,
  'comments': 42,
  'collecitons': 942},
 {'video_id': 'Download video (18)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 12.933333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(18)..mp4',
  'likes': 1889,
  'comments': 14,
  'collecitons': 57},
 {'video_id': 'Download video (25)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 10.343677,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(25)..mp4',
  'likes': 7376,
  'comments': 37,
  'collecitons': 145},
 {'video_id': 'Download video (25)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 10.385385,
  'end_time': 16.641641,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(25)..mp4',
  'likes': 7376,
  'comments': 37,
  'collecitons': 145},
 {'video_id': 'Download video (29)..txt',
  'num_clips': 5,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 2.0,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(29)..mp4',
  'likes': 11700,
  'comments': 65,
  'collections': 143000},
 {'video_id': 'Download video (29)..txt',
  'num_clips': 5,
  'clip_num': 1,
  'start_time': 2.016666,
  'end_time': 3.516666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(29)..mp4',
  'likes': 11700,
  'comments': 65,
  'collections': 143000},
 {'video_id': 'Download video (29)..txt',
  'num_clips': 5,
  'clip_num': 2,
  'start_time': 3.533333,
  'end_time': 5.166666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(29)..mp4',
  'likes': 11700,
  'comments': 65,
  'collections': 143000},
 {'video_id': 'Download video (29)..txt',
  'num_clips': 5,
  'clip_num': 3,
  'start_time': 5.183333,
  'end_time': 6.433333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(29)..mp4',
  'likes': 11700,
  'comments': 65,
  'collections': 143000},
 {'video_id': 'Download video (29)..txt',
  'num_clips': 5,
  'clip_num': 4,
  'start_time': 6.466666,
  'end_time': 11.3,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(29)..mp4',
  'likes': 11700,
  'comments': 65,
  'collections': 143000},
 {'video_id': 'Download video (14)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 17.933333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(14)..mp4',
  'likes': 175100,
  'comments': 420,
  'collections': 418000},
 {'video_id': 'Download video (14)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 17.966666,
  'end_time': 24.4,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(14)..mp4',
  'likes': 175100,
  'comments': 420,
  'collections': 418000},
 {'video_id': 'Download video (14)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 24.433333,
  'end_time': 78.1,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(14)..mp4',
  'likes': 175100,
  'comments': 420,
  'collections': 418000},
 {'video_id': 'Download video (30)..txt',
  'num_clips': 5,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 1.935268,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(30)..mp4',
  'likes': 8802,
  'comments': 39,
  'collecitons': 539},
 {'video_id': 'Download video (30)..txt',
  'num_clips': 5,
  'clip_num': 1,
  'start_time': 1.968635,
  'end_time': 5.472138,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(30)..mp4',
  'likes': 8802,
  'comments': 39,
  'collecitons': 539},
 {'video_id': 'Download video (30)..txt',
  'num_clips': 5,
  'clip_num': 2,
  'start_time': 5.505505,
  'end_time': 9.275942,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(30)..mp4',
  'likes': 8802,
  'comments': 39,
  'collecitons': 539},
 {'video_id': 'Download video (30)..txt',
  'num_clips': 5,
  'clip_num': 3,
  'start_time': 9.309309,
  'end_time': 14.447780999999999,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(30)..mp4',
  'likes': 8802,
  'comments': 39,
  'collecitons': 539},
 {'video_id': 'Download video (30)..txt',
  'num_clips': 5,
  'clip_num': 4,
  'start_time': 14.481147,
  'end_time': 41.775108,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(30)..mp4',
  'likes': 8802,
  'comments': 39,
  'collecitons': 539},
 {'video_id': 'Download video (48)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 12.812812,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(48)..mp4',
  'likes': 6294,
  'comments': 28,
  'collecitons': 153},
 {'video_id': 'Download video (48)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 12.846179,
  'end_time': 18.852185,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(48)..mp4',
  'likes': 6294,
  'comments': 28,
  'collecitons': 153},
 {'video_id': 'Download video (48)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 18.885552,
  'end_time': 19.686353,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(48)..mp4',
  'likes': 6294,
  'comments': 28,
  'collecitons': 153},
 {'video_id': 'Download video (32)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.1,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(32)..mp4',
  'likes': 6611,
  'comments': 115,
  'collecitons': 189},
 {'video_id': 'Download video (32)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 3.116666,
  'end_time': 6.316666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(32)..mp4',
  'likes': 6611,
  'comments': 115,
  'collecitons': 189},
 {'video_id': 'Download video (16)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 6.633333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(16)..mp4',
  'likes': 15600,
  'comments': 64,
  'collections': 116000},
 {'video_id': 'Download video (16)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 6.666666,
  'end_time': 13.333333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(16)..mp4',
  'likes': 15600,
  'comments': 64,
  'collections': 116000},
 {'video_id': 'Download video (12)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 7.033333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(12)..mp4',
  'likes': 7185,
  'comments': 10,
  'collecitons': 129},
 {'video_id': 'Download video (12)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 7.066666,
  'end_time': 21.833333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(12)..mp4',
  'likes': 7185,
  'comments': 10,
  'collecitons': 129},
 {'video_id': 'Download video.txt',
  'num_clips': 4,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 22.255588,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video.mp4',
  'likes': 2273,
  'comments': 22,
  'collecitons': 64},
 {'video_id': 'Download video.txt',
  'num_clips': 4,
  'clip_num': 1,
  'start_time': 22.288955,
  'end_time': 31.631631,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video.mp4',
  'likes': 2273,
  'comments': 22,
  'collecitons': 64},
 {'video_id': 'Download video.txt',
  'num_clips': 4,
  'clip_num': 2,
  'start_time': 31.664998,
  'end_time': 45.812479,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video.mp4',
  'likes': 2273,
  'comments': 22,
  'collecitons': 64},
 {'video_id': 'Download video.txt',
  'num_clips': 4,
  'clip_num': 3,
  'start_time': 45.845845,
  'end_time': 55.355355,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video.mp4',
  'likes': 2273,
  'comments': 22,
  'collecitons': 64},
 {'video_id': 'Download video (36)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 17.934601,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(36)..mp4',
  'likes': 6553,
  'comments': 34,
  'collecitons': 304},
 {'video_id': 'Download video (36)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 17.951284,
  'end_time': 24.708041,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(36)..mp4',
  'likes': 6553,
  'comments': 34,
  'collecitons': 304},
 {'video_id': 'Download video (34)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 11.044377,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(34)..mp4',
  'likes': 2864,
  'comments': 16,
  'collecitons': 98},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 1.084417,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 1,
  'start_time': 1.126126,
  'end_time': 1.835168,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 2,
  'start_time': 1.876876,
  'end_time': 2.669336,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 3,
  'start_time': 2.7110440000000002,
  'end_time': 4.796463,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 4,
  'start_time': 4.838171,
  'end_time': 5.755755,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 5,
  'start_time': 5.797464,
  'end_time': 6.92359,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 6,
  'start_time': 6.965298,
  'end_time': 7.966299,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 7,
  'start_time': 8.008008,
  'end_time': 14.014014,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 8,
  'start_time': 14.055722,
  'end_time': 15.265265,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 9,
  'start_time': 15.306973,
  'end_time': 16.474808,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 10,
  'start_time': 16.516516,
  'end_time': 17.642642,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 11,
  'start_time': 17.684351,
  'end_time': 20.061728,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 12,
  'start_time': 20.103436,
  'end_time': 21.479813,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (10)..txt',
  'num_clips': 14,
  'clip_num': 13,
  'start_time': 21.521521,
  'end_time': 24.10744,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(10)..mp4',
  'likes': 4420,
  'comments': 76,
  'collecitons': 90},
 {'video_id': 'Download video (9)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 4.833333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(9)..mp4',
  'likes': 5487,
  'comments': 39,
  'collecitons': 244},
 {'video_id': 'Download video (9)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 4.866666,
  'end_time': 31.566666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(9)..mp4',
  'likes': 5487,
  'comments': 39,
  'collecitons': 244},
 {'video_id': 'Download video (9)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 31.6,
  'end_time': 58.733333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(9)..mp4',
  'likes': 5487,
  'comments': 39,
  'collecitons': 244},
 {'video_id': 'Download video (33)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 11.144477,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(33)..mp4',
  'likes': 31600,
  'comments': 132,
  'collections': 69000},
 {'video_id': "'Callaway TikTok.txt",
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 17.917917,
  'video_url': "https://storage.googleapis.com/callaway_video/'Callaway%20TikTok.mp4",
  'likes': 94900,
  'comments': 245,
  'collections': 63000},
 {'video_id': 'Download video (17)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 4.466666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(17)..mp4',
  'likes': 5593,
  'comments': 61,
  'collecitons': 156},
 {'video_id': 'Download video (17)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 4.5,
  'end_time': 9.8,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(17)..mp4',
  'likes': 5593,
  'comments': 61,
  'collecitons': 156},
 {'video_id': 'Download video (17)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 9.833333,
  'end_time': 12.966666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(17)..mp4',
  'likes': 5593,
  'comments': 61,
  'collecitons': 156},
 {'video_id': 'Download video (15)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 5.666666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(15)..mp4',
  'likes': 2090,
  'comments': 17,
  'collecitons': 40},
 {'video_id': 'Download video (15)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 5.7,
  'end_time': 19.566666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(15)..mp4',
  'likes': 2090,
  'comments': 17,
  'collecitons': 40},
 {'video_id': 'Download video (15)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 19.6,
  'end_time': 25.7,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(15)..mp4',
  'likes': 2090,
  'comments': 17,
  'collecitons': 40},
 {'video_id': 'Download video (28)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 5.3,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(28)..mp4',
  'likes': 170200,
  'comments': 618,
  'collections': 449000},
 {'video_id': 'Download video (28)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 5.333333,
  'end_time': 10.566666,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(28)..mp4',
  'likes': 170200,
  'comments': 618,
  'collections': 449000},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 12.387387,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 1,
  'start_time': 12.429095,
  'end_time': 15.015015,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 2,
  'start_time': 15.056723,
  'end_time': 16.850183,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 3,
  'start_time': 16.891891,
  'end_time': 20.145145,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 4,
  'start_time': 20.186853,
  'end_time': 22.439105,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 5,
  'start_time': 22.480814,
  'end_time': 28.403403,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 6,
  'start_time': 28.445111,
  'end_time': 30.488822,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 7,
  'start_time': 30.53053,
  'end_time': 32.198865,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 8,
  'start_time': 32.240573,
  'end_time': 37.871204,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 9,
  'start_time': 37.912912,
  'end_time': 47.797797,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 10,
  'start_time': 47.839506,
  'end_time': 50.925925,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 11,
  'start_time': 50.967634,
  'end_time': 53.678678,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 12,
  'start_time': 53.720387,
  'end_time': 59.601267,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 13,
  'start_time': 59.642976,
  'end_time': 61.102769,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 14,
  'start_time': 61.144477,
  'end_time': 62.979646,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 15,
  'start_time': 63.021354,
  'end_time': 65.19019,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 16,
  'start_time': 65.231898,
  'end_time': 71.071071,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 17,
  'start_time': 71.112779,
  'end_time': 77.160493,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (49)..txt',
  'num_clips': 19,
  'clip_num': 18,
  'start_time': 77.202202,
  'end_time': 80.497163,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(49)..mp4',
  'likes': 2898,
  'comments': 13,
  'collecitons': 64},
 {'video_id': 'Download video (31)..txt',
  'num_clips': 3,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 6.6,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(31)..mp4',
  'likes': 63900,
  'comments': 354,
  'collections': 232000},
 {'video_id': 'Download video (31)..txt',
  'num_clips': 3,
  'clip_num': 1,
  'start_time': 6.633333,
  'end_time': 7.433333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(31)..mp4',
  'likes': 63900,
  'comments': 354,
  'collections': 232000},
 {'video_id': 'Download video (31)..txt',
  'num_clips': 3,
  'clip_num': 2,
  'start_time': 7.466666,
  'end_time': 11.7,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(31)..mp4',
  'likes': 63900,
  'comments': 354,
  'collections': 232000},
 {'video_id': 'Download video (35)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 23.983333000000002,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(35)..mp4',
  'likes': 3758,
  'comments': 17,
  'collecitons': 73},
 {'video_id': 'Download video (11)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 19.786453,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(11)..mp4',
  'likes': 6172,
  'comments': 15,
  'collecitons': 108},
 {'video_id': 'Download video (8)..txt',
  'num_clips': 1,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 22.458333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(8)..mp4',
  'likes': 4669,
  'comments': 39,
  'collecitons': 223},
 {'video_id': 'Download video (13)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 3.053053,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(13)..mp4',
  'likes': 24500,
  'comments': 176,
  'collections': 43000},
 {'video_id': 'Download video (13)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 3.069736,
  'end_time': 19.919919,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(13)..mp4',
  'likes': 24500,
  'comments': 176,
  'collections': 43000},
 {'video_id': 'Download video (37)..txt',
  'num_clips': 2,
  'clip_num': 0,
  'start_time': 0.0,
  'end_time': 8.633333,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(37)..mp4',
  'likes': 42700,
  'comments': 351,
  'collections': 172000},
 {'video_id': 'Download video (37)..txt',
  'num_clips': 2,
  'clip_num': 1,
  'start_time': 8.666666,
  'end_time': 15.5,
  'video_url': 'https://storage.googleapis.com/callaway_video/Download%20video%20(37)..mp4',
  'likes': 42700,
  'comments': 351,
  'collections': 172000}]