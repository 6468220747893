import { useNavigate } from 'react-router';
import { Box, Tooltip, Typography, IconButton } from '@mui/material';
import useAuth from 'hooks/useAuth';

// If you want to use your SVG as the logout icon
import LogoutIcon from 'assets/images/logout.svg';

// If you are using Auth0 for authentication
import { useAuth0 } from "@auth0/auth0-react";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    logout({ returnTo: window.location.origin });
  };

  const userName = localStorage.getItem('userName');

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
      <Typography variant="subtitle1" sx={{ mr: 2 }}>
        {userName}
      </Typography>
      <Tooltip title="Logout">
        <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleLogout}>
          <img src={LogoutIcon} alt="Logout"  style={{ width: '20px' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Profile;
