
// material-ui
import logo from 'assets/images/logo.png';
//import logo from 'assets/store.png';


// ==============================|| LOGO ||============================== //

const LogoMain = () => {
  return (
    <>
      <img src={logo} alt="Instalily" width="180" />
    </>
  );
};

export default LogoMain;
