import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { SearchContext } from 'contexts/SearchContext';
import { VideoContext } from 'contexts/VideoContext';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info'
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // Apply styles to the root element directly
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(25, 117, 210,0.5)', 
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth:'2px',
              borderColor: '#1976d2', // Border color on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1976d2', // Border color when input is focused
              borderWidth: '2px', // Make the border thicker when focused, if desired
            },
          },
          input: {
            // Apply styles to the input element directly
            '&.Mui-focused': {
              backgroundColor: '#ffffff',
            }
          },
        },
      },
    },
  });
  

export function SearchBox() {

    const { handleSubmit,handleClear, getAIMessage } = useContext(SearchContext);
    const { onSearch } = useContext(VideoContext);

    const location = useLocation(); // Retrieve location object

    // Define a new function that conditionally decides which submit handler to call
    const conditionalSubmit = (query) => {
      console.log(query)
      if (location.pathname.endsWith('/videosearch')) {
        console.log('video')
        onSearch(query);
      } else if (location.pathname.endsWith('/search')) {
        handleSubmit(query);
      }
    };

    // Clearing 
    const conditionalClear = () => {
      if (location.pathname.endsWith('/videosearch')) {
        onSearch('');
      } else if (location.pathname.endsWith('/search')) {
        handleClear();
      }
    };
    
    const [localQ, setLocalQ] = useState(''); // question start
  
    return (
      <>
      {!location.pathname.endsWith('/dashboard/creative') && !location.pathname.endsWith('/dashboard/reviews') && !location.pathname.endsWith('/agent/chat') && 
        <ThemeProvider theme={theme}>
            <Stack direction="row" alignItems="center"> 
                {/* Input */}
                <div>
                <OutlinedInput
                    size="large"
                    style={{ width: '500px',height:'36px', borderRadius: '10px' }} // background: question ? '#ffffff' : '#edf2fc',
                    sx={{
                        width: '500px',
                        height: '30px',
                        borderRadius: '10px',
                        backgroundColor:'#edf2fc',
                        '&.Mui-focused': {
                          backgroundColor: '#ffffff', 
                        },
                        '& .MuiInputBase-input::placeholder': { // targeting the placeholder
                          fontSize: '14px', // set your desired font size
                          color: 'rgba(0, 0, 0, 0.6)' // optionally set your desired color
                        }
                    }}
                    autoComplete='off'
                    value={localQ || ''}
                    onChange={e => setLocalQ(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          if (localQ === '') {
                            handleClear(); // Call handleClear if localQ is an empty string
                          } else {
                            conditionalSubmit(localQ); // Otherwise, submit the query
                          }
                        }
                      }}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                style={{
                                    borderRadius: '10px', 
                                }}
                                onClick={() => {conditionalSubmit(localQ);}}
                                onMouseEnter={(e) => (e.currentTarget.style.background = 'rgba(0, 0, 0, 0.04)')}
                                onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}
                            >
                                <SearchIcon style={{ fontSize: '18px', width: '18px', height: 'auto' }} /> {/* Search icon */}
                            </IconButton>
                        </InputAdornment>
                    }
                    /* endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                style={{
                                    borderRadius: '10px', 
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.background = 'rgba(0, 0, 0, 0.04)')}
                                onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}
                            >
                                <DensityMediumIcon style={{ fontSize: '16px', width: '16px', height: 'auto' }} /> 
                            </IconButton>
                        </InputAdornment>
                    } */
                    placeholder="Search anything..."
                    id="start-adornment-email"
                />
                </div>
                {/* Submit */}
                <Button style={{marginLeft:'9px',borderRadius:'8px',height:"33px", width:'100px'}} 
                        variant="contained" 
                        onClick={() => {conditionalSubmit(localQ);}}
                        startIcon={<SearchOutlined style={{ color: 'white', margin: '0'}} />}
                        sx={{fontSize: '12px'}}>
                    Search
                </Button>
                {/* ASK AI */}
                {location.pathname.endsWith('/search') &&
                <Button
                  variant="contained"
                  style={{marginLeft:'9px',borderRadius:'8px',minWidth: '0',height:"33px", width:'100px',backgroundColor:'secondary'}}
                  startIcon={<AutoAwesomeIcon style={{ color: '#fff' }} />} 
                  sx={{
                    fontSize: '12px',
                    backgroundImage: 'linear-gradient(to bottom, #9c27b0, #6528bb)',
                    color: 'white',

                    ':hover': {
                      backgroundImage: 'linear-gradient(to bottom, #9c27b0, #503276)'
                    }
                  }}
                  onClick={() => {getAIMessage(localQ)}}
                >
                  Ask AI
                </Button>
                }
                {/* Clear */}
                <Button style={{marginLeft:'9px',borderRadius:'8px',minWidth: '0',height:"33px", width:'33px',backgroundColor:'#b5b5b5'}} 
                    variant="contained"
                    onClick={() => {
                        conditionalClear()
                        setLocalQ('');
                    }}>
                    <SyncOutlined style={{ color: 'white', margin: '0'}} />
                </Button>
            </Stack>
        </ThemeProvider>
        }
      </>
    );
}



/* 


import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import React, { useContext, useState,useRef } from 'react';
import { SearchContext, SearchProvider } from 'contexts/SearchContext';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info'
import DensityMediumIcon from '@mui/icons-material/DensityMedium';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';

const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          '&$focused': {
            backgroundColor: '#ffffff', // Change to the desired background color when focused
          },
        },
        input: {
          '&$focused': {
            backgroundColor: '#ffffff', // This ensures the input field is white when focused
          },
        },
      },
    },
});
  
export function SearchBox() {

    const { question,setQuestion,handleSubmit,setResponse } = useContext(SearchContext);

    const [open, setOpen] = useState(false);    
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((prev) => !prev);
    };
  
    const handleClose = (event) => {
        if (anchorEl && anchorEl.contains(event.target)) {
            return;
        }
        setAnchorEl(null);
        setOpen(false);
    };

    const inputRef = useRef(null);

    const inputStyle = {
        width: '500px',
        height: '50px',
        borderRadius: open ? '15px 15px 0 0' : '15px', // Rounded corners when closed, square on the bottom when open
        backgroundColor: open ? '#ffffff' : '#edf2fc', // White background when open
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main', // Or another color
            borderWidth: '1px solid #edf2fc',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: open ? '15px 15px 0 0' : '15px', // Apply the same borderRadius logic here
        },
    };
  
    return (
        <ThemeProvider theme={theme}>
            <Stack direction="row" alignItems="center"> 
                <div ref={inputRef} style={{ width: '500px' ,height: '50px',}}>
                <OutlinedInput
                    size="large"
                    style={{ borderRadius: '15px' }}
                    autoComplete='off'
                    sx={inputStyle}
                    value={question || ''}
                    onClick={handleClick}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(question);
                    }
                    }}
                    startAdornment={
                    <InputAdornment position="start">
                        <IconButton
                        style={{ borderRadius: '50%' }}
                        onMouseEnter={(e) => (e.currentTarget.style.background = 'rgba(0, 0, 0, 0.04)')}
                        onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}
                        >
                        <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    }
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        style={{ borderRadius: '50%' }}
                        onMouseEnter={(e) => (e.currentTarget.style.background = 'rgba(0, 0, 0, 0.04)')}
                        onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}
                        onClick={handleToggle}
                        >
                        <DensityMediumIcon />
                        </IconButton>
                    </InputAdornment>
                    }
                    placeholder="Search records..."
                    id="start-adornment-email"
                />
                <Popper open={open} anchorEl={inputRef.current} role={undefined} transition 
                    style={{
                        width: inputRef.current ? inputRef.current.offsetWidth : null,
                        zIndex: theme.zIndex.modal,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        marginTop: '-1px',
                        // Make sure the border does not show between the input and popper
                        border: open ? '1px solid transparent' : 'none', 
                    }}>
                    {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        timeout={300}
                        style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList sx={{overflow:'hidden'}} autoFocusItem={open} id="menu-list-grow">
                            <MenuItem onClick={handleClose}>Option 1</MenuItem>
                            <MenuItem onClick={handleClose}>Option 2</MenuItem>
                            <MenuItem onClick={handleClose}>Option 3</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Popper>
                </div>
            </Stack>
        </ThemeProvider>
    );
}
*/