import axios from 'axios'
import { globalVariable } from './global'

export const PostData = async (url, data, token) => {
  return await axios
    .post(globalVariable.baseURL + url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    .then(function (response) {
      const returnData = response.data
      returnData.responseCode = response?.status
      return returnData
    })
    .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
          }, 1000)
        }
        return error?.response?.data
      } else {
        return error
      }
    })
};

export const GetData = async (url, token) => {
  return await axios
    .get(globalVariable.baseURL + url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then(function (response) {
      return response.data
    })
    /* .catch(function (error) {
      if (error?.response) {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
          }, 1000)
        }
        return error?.response?.data
      } else {
        return error
      }
    }) */
  };

export const QueryGet = async (url, params, token) => {
  return await axios
    .get(globalVariable.baseURL + url, {
      params: params, 
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error("Error from server:", error.response);
      throw error;
    });
};

export const QueryDelete = async (url, params, token) => {
  return await axios
    .delete(globalVariable.baseURL + url, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error("Error from server:", error.response);
      throw error;
    });
};

export const CallApi = async (url, data) => {
  return await axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      const returnData = response.data;
      returnData.responseCode = response?.status;
      return returnData;
    })
    .catch(function (error) {
      if (error?.response) {
        return {};
      } else {
        return {};
      }
    });
};

// Catch function routes to home when API fails
/* 
.catch(function (error) {
  if (error?.response) {
    if (error?.response?.status === 401) {
      setTimeout(() => {
        localStorage.clear()
        window.location.href = '/'
      }, 1000)
    }
    return error?.response?.data
  } else {
    return error
  }
})
 */