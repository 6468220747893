// Format date as a string
export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`;
};  

// Convert formatted date to dictionary
export const transformRangeArrayToDict = (ranges) => ({
      startDate: formatDate(ranges[0]),
      endDate: formatDate(ranges[1])
});

// Return a list of campaigns given a 'name' either ai tag, adset name, or ad category
export const getCampaigns = (name, chart) => {
    const campaignString = chart.find(item => item.name === name)?.campaigns || '';
    const campaignsArray = campaignString
      .replace(/{|}/g, '') 
      .split(',')
      .map(campaign => campaign.trim())
      .filter(campaign => campaign !== ''); 
    return campaignsArray;
  };

// Check is a number is a numeric value (helper for axis options)
export const isNumeric = (value) => {
    return typeof value === 'number' && !isNaN(value);
}

