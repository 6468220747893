// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import Locales from "components/Locales";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import { useState } from "react";
import { SearchProvider } from "contexts/SearchContext";
import { VideoProvider } from 'contexts/VideoContext';

function App() {
  return (
    <ThemeCustomization>
      <VideoProvider>
      <SearchProvider>
      <Locales>
        <ScrollTop>
          <>
            <Notistack>
              <Routes />
              <Snackbar />
            </Notistack>
          </>
        </ScrollTop>
      </Locales>
      </SearchProvider>
      </VideoProvider>
    </ThemeCustomization>
  );
}

export default App;
