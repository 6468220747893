// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  SearchOutlined,
  StopOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  SearchOutlined,
  LineChartOutlined,
  FolderOutlined,
  DatabaseOutlined,
  VideoCameraOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const agent = {
  id: 'Agent',
  title: 'Agent',
  type: 'group',
  children: [
    {
      id: 'Chat',
      title: <FormattedMessage id="Chat" />,
      type: 'item',
      url: 'agent/chat',
      icon: icons.SmileOutlined
    },
  ]
};

export default agent;
